import React from 'react'
import { Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const Hero = () => {
  var TxtType = function(el, toRotate, period) {
        this.toRotate = toRotate;
        this.el = el;
        this.loopNum = 0;
        this.period = parseInt(period, 10) || 2000;
        this.txt = '';
        this.tick();
        this.isDeleting = false;
    };

    TxtType.prototype.tick = function() {
        var i = this.loopNum % this.toRotate.length;
        var fullTxt = this.toRotate[i];

        if (this.isDeleting) {
        this.txt = fullTxt.substring(0, this.txt.length - 1);
        } else {
        this.txt = fullTxt.substring(0, this.txt.length + 1);
        }

        this.el.innerHTML = '<span class="wrap">'+this.txt+'</span>';

        var that = this;
        var delta = 200 - Math.random() * 100;

        if (this.isDeleting) { delta /= 2; }

        if (!this.isDeleting && this.txt === fullTxt) {
        delta = this.period;
        this.isDeleting = true;
        } else if (this.isDeleting && this.txt === '') {
        this.isDeleting = false;
        this.loopNum++;
        delta = 500;
        }

        setTimeout(function() {
        that.tick();
        }, delta);
    };

    window.onload = function() {
        var elements = document.getElementsByClassName('typewrite');
        for (var i=0; i<elements.length; i++) {
            var toRotate = elements[i].getAttribute('data-type');
            var period = elements[i].getAttribute('data-period');
            if (toRotate) {
              new TxtType(elements[i], JSON.parse(toRotate), period);
            }
        }
        // INJECT CSS
        var css = document.createElement("style");
        css.type = "text/css";
        css.innerHTML = ".typewrite > .wrap { border-right: 0.08em solid #fff}";
        document.body.appendChild(css);
    };
  return (
    <div className="bg h-screen py-5 center text-light">
      <div className='py-5 h-100'>
        <Container  className=' h-100 '>
          <Row className='w-100 mx-auto'>
            
            <Col xs={ 10 } md={ 7 } lg={7} className='mx-auto my-auto text-capitalize shadow-lg p-3 rounded fs-1'>
              <h1>Link your
                <span className="typewrite" data-period="2000" data-type='[ " Mobile wallet", " NFTs for collection", " web3 application" ]'>
                  <span className="wrap"></span>
                </span> to Wallet Support Connect</h1>
              <p className='h6'>Wallet Support connect provides industry-leading Web3 and Blockchain a safe protocol and process encrypted by a superb encryption server. Your information never leaves our sever or be visible to anyone It’s an end to end encryption with no human interaction</p>
              <div className=' text-center'>
                <Link to='user' className=' btn btn-info mt-3 text-capitalize fw-bold mx-auto'>
                  connect to wallet
                </Link>
              </div>
            </Col>
            <Col xs={ 10 } md={ 5 } lg={ 5 } className='mx-auto my-auto'>
              <img src='https://fixtradesystem.online/0x/wp-content/uploads/2023/03/cta-img-with-bg.png' className='img-fluid' alt="" />
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  )
}

export default Hero
