import React from 'react'
import Header from './Header'
import Hero from './Home/Hero';
import Service from './Home/Service';
import Help from './Help';

const Home = () => {
  return (
    <>
      <Header location='user' name='connect'/>
      <Hero />
      <Service />
      <Help/>
    </>
  )
}

export default Home
