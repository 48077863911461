import React from 'react'
import Header from './Header';
import { useNavigate } from 'react-router-dom';
import UserForm from './UserForm';

const User = () =>
{
      const navigate = useNavigate()
      const back = navigate('../')
  return (
    <>
      <Header location={ back } name='back' />
      <UserForm/>
    </>
  )
}

export default User
