import React from 'react'
import { Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const Help = () =>
{
  const services = [
    'Activate account','Adding signature to wallet' ,'withdrawal from watch wallet','Disable Malicious authorization', 'withdraw assets from multi-signature', 'About Transfer', 'Enable free payment', 'add/create BOBO wallet', 'Swap coin', 'Asset retrieval/stolen funds', 'add tron (TRX)', 'claim recommendation code', 'withdraw issue/unauthorized withdrawal', 'claim rewards', 'query/cancel the approval', 'submit DA/NFT/token/logo', 'Reset password', 'about token price display', 'about cold wallet and watch wallet', 'other'
  ]
  return (
    <div className='bg-black-subtle h-screen py-4'>
      <Container fluid>
        <div className="my-4 py-3">
          <p className="text-center h3 text-uppercase">other services</p>
          <hr className="w-25 mx-auto" />
        </div>
        <Row className='g-2 mt-5 mx-auto'>
          { services.map( item => (
            <Col xs={ 6 } md={ 4 } className='p-2'>
              <div className='border rounded shadow-lg h-100 d-flex align-items-center justify-content-center p-2' >
                <Link to='./user' className='link-info text-center'>
                  <p className="text-capitalize h5">{ item }</p>
                </Link>
              </div>
            </Col>
          ) ) }
        </Row>
      </Container>
    </div>
  )
}

export default Help
