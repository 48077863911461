import React from 'react'
import { Col, Container, Row } from 'react-bootstrap';
const Footer = () =>
{
  const year = new Date().getFullYear()
  return (
    <footer className="bg-black bg-opacity-25 text-light fixed-bottom">
      <Container>
        <Row>
          <Col xs={ 8 } className='mx-auto'>
            <p className='text-center h5'>Wallet Support connect</p>
            <p className='text-center'>&copy; Copyright {year} </p>
          </Col>
        </Row>
      </Container>
    </footer>
  )
}

export default Footer
