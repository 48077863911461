import React from 'react'
import { Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import img1 from '../../assets/claimReward.png'
import img2 from '../../assets/transaction.png'
import img3 from '../../assets/migration.png'
import img4 from '../../assets/kyc.png'
import img5 from '../../assets/sync.png'
import img6 from '../../assets/recover.png'
import img7 from '../../assets/rectify.png'
import img8 from '../../assets/auth.png'
import img9 from '../../assets/swapErr.png'
import img10 from '../../assets/stake.png'
import img11 from '../../assets/mint.png'
import img12 from '../../assets/airdrop.png'
import img14 from '../../assets/node.png'
import img15 from '../../assets/mining.png'

const Service = () =>
{
  const services = [
    {
      src: img1,
      text: 'claim rewards'
    },
    {
      src: img2,
      text: 'transaction error'
    },
    {
      src: img3,
      text: 'migration'
    },
    {
      src: img4,
      text: 'kyc verification'
    },
    {
      src: img5,
      text: 'synchronisation'
    },
    {
      src: img6,
      text: 'recover funds'
    },
    {
      src: img7,
      text: 'rectify'
    },
    {
      src: img8,
      text: 'authenticate'
    },
    {
      src: img9,
      text: 'swap error'
    },
    {
      src: img10,
      text: 'stake/unstake'
    },
    {
      src: img11,
      text: 'mint nft'
    },
    {
      src: img12,
      text: 'claim airdrop'
    },
    {
      src: img15,
      text: 'Withdraw assets from mining pool'
    },
    {
      src: img14,
      text: 'node validator'
    },
  ]
  return (
    <div className='bg-dark text-white'>
      <Container>
        <p className="w-75 h3 mx-auto text-center text-uppercase text-light pt-4">Make your selection</p>
        <hr className="mx-auto w-25" />
        <Row>
          { services.map( service => (
            <Col xs={ 10 } md={ 6 } lg={ 3 } className='p-4 text-center my-auto mx-auto'>
            <Link to='user' className='nav-link p-3 rounded shadow-lg'>
              <img className='img-fluid mx-auto' src={service.src} alt='' width={200} height={200} />
              <p className="h5 text-capitalize text-center">{service.text}</p>
            </Link>
          </Col>
          ))}
        </Row>
      </Container>
    </div>
  )
}

export default Service
