import React from 'react'
import { Button, Modal, ModalBody, ModalFooter } from 'react-bootstrap';
import {BsCheckCircle} from 'react-icons/bs'

const SuccessModal = ({show, hide,myRef}) => {
  return (
    <Modal centered size='lg' aria-labelledby="contained-modal-title-vcenter" show={show} onHide={hide}>
      <ModalBody>
        <div>
          <div className="d-flex justify-content-center display-1 text-success my-4">
            <BsCheckCircle className='display-1'/>
          </div>
          <p className="h4 text-center">Your request was sent sucessfully and and a mail will be sent to you with your refrence number. Thanks for contacting us
          </p>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button variant='outline-primary' onClick={hide}>Close</Button>
      </ModalFooter>
    </Modal>
  )
}

export default SuccessModal
