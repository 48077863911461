import React from 'react'
import { Container, Navbar, NavbarBrand} from 'react-bootstrap'
import { Link } from 'react-router-dom';
import img from '../assets/logo.png'

const Header = ({location,name}) => {
  return (
    <Navbar expand="lg" className='bg-transparent' fixed='top'>
      <Container>
        <NavbarBrand>
          <img src={ img } width={50} alt='' />
        </NavbarBrand>
        <Link to={location} className='btn btn-outline-info text-capitalize'>
          {name}
        </Link>
      </Container>
    </Navbar>
  )
}

export default Header
